import React from 'react'

import Layout from '../components/layout'

const RefundPage = () => {
    return (
        <Layout>
          
          <div className="relative overflow-hidden py-16">
          <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
            <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
              <svg
                className="absolute top-12 left-full translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
              </svg>
              <svg
                className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
              >
                <defs>
                  <pattern
                    id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
              </svg>
              
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-prose text-lg">
                <h1>
                    <span className="block text-center text-lg font-semibold text-purple-600">Last Revised: Sep 18,2022</span>
                    <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                    Cancellation and Refund
                    </span>
                </h1>
                <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-15 lg:px-8 xl:pl-12">
                    <p className="max-w-3xl text-lg text-gray-900">
                        PLEASE READ THIS CANCELLATION AND REFUND POLICY CAREFULLY, AS IT CONTAINS IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND RESPONSIBILITIES.
                        <br/><br/>
                        This Cancellation and Refund Policy (“Policy”) is an integral part of the Terms of Service Agreement between you and Shieldstack. By accessing or using the Sites or Service, you acknowledge and agree to be bound by the terms and conditions of this Policy. If you do not agree with this Policy, you may not access or use the Service.                       
                    </p>
                    <h4 className="text-lg font-bold my-4">Cancellation Period and Refund Eligibility</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        Upon subscribing to the Service, you are granted a thirty (30) day Cancellation Period from the Service Commencement Date or any Renewal Commencement Date. During this period, you have the right to cancel the Service and receive a refund of the Service Subscription Fee, provided that you have not submitted a Malware Removal Request during the Cancellation Period.
                    </p>
                    <h4 className="text-lg font-bold my-4">Cancellation Process</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        To initiate a cancellation, you must submit a general request ticket through the Company’s ticketing system within the Cancellation Period. If you are using a PayPal account for payments, you must also log in and cancel your recurring payments via your PayPal interface.
                    </p>
                    <h4 className="text-lg font-bold my-4">Refund Terms</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        Except as specified above, the Service Subscription Fee is non-refundable. We understand the importance of a timely refund process. Therefore, Shieldstack commits to processing and crediting the refund amount to the customer's bank account within 5-7 working days from the date of the approved cancellation.
                        <br/><br/>
                        Once the Cancellation Period has expired or if you have submitted a Malware Removal Request during the Cancellation Period, no refunds will be issued.
                    </p>
                    <h4 className="text-lg font-bold my-4">Automatic Renewal</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        All Services are offered on automatic renewal to ensure continuity of service. If you do not wish for any Service to automatically renew, you may elect to cancel renewal. Failure to do so may result in an automatic renewal, and Shieldstack shall not be liable for any interruption or loss of service in such cases.
                    </p>
                    <h4 className="text-lg font-bold my-4">Modification of Agreement</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        Shieldstack reserves the right to change or modify this Cancellation and Refund Policy, and any such changes will be effective immediately upon posting on the Sites. Your continued use of the Service after such changes constitutes acceptance of the modified Policy. If you do not agree with the modified Policy, you must discontinue use of the Service.
                    </p>
                    <h4 className="text-lg font-bold my-4">Contact Information</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        For any questions or concerns regarding this Cancellation and Refund Policy, please contact Shieldstack at the following address support@shiledstack.io
                    </p>
                    <h4 className="text-lg font-bold my-4">Language Controls</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        This Policy is executed in the English language. Any translation provided is for convenience only, and in case of conflict, the English version will prevail.
                    </p>
                    <h4 className="text-lg font-bold my-4">Waiver and Assignment</h4>
                    <p className="max-w-3xl text-lg text-gray-900">
                        No waiver or failure to enforce any provision of this Policy on one occasion will be deemed a waiver of any other provision or of such provision on any other occasion. Assignment of rights and obligations under this Policy requires Shieldstack’s prior written consent.
                    </p>                                   
                </div>            
            </div>        
          </div>
        </div>
    
        </Layout>
      )
}

export default RefundPage